import { Navigate, Outlet, useLocation } from "react-router-dom"
import { Container } from "@mui/material"
import { URL_LOGIN } from "constants/urls"
import Header from "components/commun/header/Header"

type Props = {
  isAuth: boolean
  userRole?: string
  allowedRoles: string[]
}

export default function PrivateRoutes(props: Props) {

  const { isAuth } = props
  const location = useLocation()

  if (!isAuth) {
    return <Navigate to={URL_LOGIN} state={{ from: location }} replace />
  }

  // if (!allowedRoles.includes(userRole as string)) {
  //   return <Navigate to={URL_DASHBOARD} state={{ from: location }} replace />
  // }

  return (
    <div className="app">
      <div className="content">
        <Header />

        <Container maxWidth="xl" sx={{ py: 8 }}>
          <Outlet />
        </Container>
      </div>
    </div>
  )
}
