import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_LEADS } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type UpdateLeadRequest = {
  id: number
  qualification?: string
  ville?: number
  mode_financement?: string
  montat_avance?: number
  heur_debut_appel?: string | null
  heur_fin_appel?: string | null
  entreprise?: string
  fonction?: string
  superficie_souhaite?: number
  nombre_chambre?: number
  age?: number
  sexe?: string
  commentaire?: string
  produit?: string
  is_qualified?: boolean
}

export type UpdateLeadResponse = {
  id: number
  full_name: string
  email: string
  phone_number: string
  is_qualified: boolean
  qualification: string
  created_time: string
  updated_at: string
}

export type UpdateLeadError = {
  detail: "400_wrong_login_or_password" | ""
}

export default async function updateLead(params: UpdateLeadRequest) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "PATCH",
    url: API_LEADS + params.id + "/",
    data: params,
  })

  return response.data as UpdateLeadResponse
}
