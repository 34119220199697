import Chip from "@mui/material/Chip"

type Props = {
  qualification: Qualification
}

export default function QualificationChip(props: Props) {

  const { qualification } = props

  if (qualification === "hot") return (
    <Chip
      label="Hot"
      variant="filled"
      sx={{ bgcolor: "#FFD7D9", color: "#A2191F" }}
    />
  )

  if (qualification === "interested") return (
    <Chip
      label="Interested"
      variant="filled"
      sx={{ bgcolor: "#A7F0BA", color: "#0E6027" }}
    />
  )

  if (qualification === "callback") return (
    <Chip
      label="Callback"
      variant="filled"
      sx={{ bgcolor: "#9EF0F0", color: "#005D5D" }}
    />
  )

  if (qualification === "unanswered") return (
    <Chip
      label="Unanswered"
      variant="filled"
      sx={{ bgcolor: "#E8DAFF", color: "#6929C4" }}
    />
  )

  if (qualification === "not_interested") return (
    <Chip
      label="Not interested"
      variant="filled"
      sx={{ bgcolor: "#FFD6E8", color: "#9F1853" }}
    />
  )

  if (qualification === "indefini") return (
    <Chip
      label="Indéfini"
      color="default"
      variant="filled"
    />
  )

  return null
}
