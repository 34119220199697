import { BarChart, Clear, Done, History, Refresh, Visibility } from "@mui/icons-material"
import { Alert, Autocomplete, Box, Button, Card, Checkbox, FormControlLabel, Grid, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { LeadsOrder } from "api/leads/getLeads"
import Loader from "components/commun/Loader"
import MainPagination from "components/commun/MainPagination"
import QualificationChip from "components/commun/QualificationChip"
import { URL_LEADS } from "constants/urls"
import { format, isValid } from "date-fns"
import useLeadsQuery from "hooks/queries/leads/useLeadsQuery"
import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { useDocumentTitle } from "usehooks-ts"
import { pagesCount, standardDateFormat, standardTimeFormat } from "utils"

const columns = [
  "Nom complet",
  "Qualifié",
  "Statut",
  "Email",
  "Phone",
  "Date",
  "Actions"
]

const ORDER_OPTIONS = [
  { value: "-created_time", label: "Date de création (décroissant)" },
  { value: "created_time", label: "Date de création (croissant)" },
  { value: "full_name", label: "Nom complet (croissant)" },
  { value: "-full_name", label: "Nom complet (décroissant)" },
  { value: "email", label: "Email (croissant)" },
  { value: "-email", label: "Email (décroissant)" },
  { value: "updated_at", label: "Date de modification (croissant)" },
  { value: "-updated_at", label: "Date de modification (décroissant)" },
]

export default function Dashboard() {

  useDocumentTitle("Digital Leads | Liste des leads")

  const [tabValue, setTabValue] = useState(0)

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [isQualified, setIsQualified] = useState(false)
  const [isWebsite, setIsWebsite] = useState(false)
  const [isFacebook, setIsFacebook] = useState(false)
  const [dateStart, setDateStart] = useState<Date | null>(null)
  const [dateEnd, setDateEnd] = useState<Date | null>(null)
  const [order, setOrder] = useState(ORDER_OPTIONS[0])

  const navigate = useNavigate()

  const leadsQuery = useLeadsQuery({
    key: {
      page : page,
      page_size: perPage,
      full_name: fullName.length >= 3 ? fullName : "",
      email: email.length >= 3 ? email : "",
      qualified: isQualified,
      is_website: isWebsite,
      is_fb_lead: isFacebook,
      creation_date_gte: dateStart ? format(dateStart, "yyyy-MM-dd") : undefined,
      creation_date_lte: dateEnd ? format(dateEnd, "yyyy-MM-dd") : undefined,
      order: order.value as LeadsOrder,
      // TODO: secteur_activite
      // TODO: campaign_id
    }
  })

  const historyQuery = useLeadsQuery({
    key: {
      historique: true
    }
  })

  function Filters() {
    return (
      <Card sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={3}>
              <Typography fontSize={22}>Leads ({leadsQuery.data?.count})</Typography>
              <Button
                color="secondary"
                variant="contained"
                startIcon={<Refresh />}
                onClick={() => {
                  toast.promise(leadsQuery.refetch(), {
                    loading: "Rafraîchissement des données...",
                    success: "Données rafraîchies",
                    error: "Une erreur est survenue",
                  })
                }}
              >
                Rafraîchir
              </Button>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              size="small"
              options={["10", "20", "50", "100"]}
              clearIcon={null}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              value={perPage.toString()}
              onChange={(e, value) => setPerPage(Number(value))}
              renderInput={(params) => <TextField {...params} type="text" label="Elements par page" />}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              size="small"
              fullWidth
              label="Recherche par nom"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              size="small"
              fullWidth
              label="Recherche par email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="Date début"
              sx={{ width: "100%" }}
              maxDate={dateEnd || null}
              value={dateStart}
              onChange={(value) => {
                if (isValid(value) || value === null) {
                  setDateStart(value)
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="Date fin"
              sx={{ width: "100%" }}
              minDate={dateStart}
              value={dateEnd}
              onChange={(value) => {
                if (isValid(value) || value === null) {
                  setDateEnd(value)
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              size="small"
              options={ORDER_OPTIONS}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={order}
              onChange={(e, value) => value && setOrder(value)}
              renderInput={(params) => <TextField {...params} type="text" label="Trier par" />}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <FormControlLabel
              label="Qualified"
              control={
                <Checkbox
                  checked={isQualified}
                  onChange={(e, checked) => setIsQualified(checked)}
                />
              }
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <FormControlLabel
              label="Website"
              control={
                <Checkbox
                  checked={isWebsite}
                  onChange={(e, checked) => setIsWebsite(checked)}
                />
              }
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <FormControlLabel
              label="Facebook"
              control={
                <Checkbox
                  checked={isFacebook}
                  onChange={(e, checked) => setIsFacebook(checked)}
                />
              }
            />
          </Grid>
        </Grid>
      </Card>
    )
  }

  function Columns() {
    return columns.map((column) => {
      return (
        <TableCell key={column}>{column}</TableCell>
      )
    })
  }

  function Rows() {
    if (leadsQuery.isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Loader />
          </TableCell>
        </TableRow>
      )
    }

    if (leadsQuery.isError) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Typography textAlign="center">Une erreur est survenue</Typography>
          </TableCell>
        </TableRow>
      )
    }

    return leadsQuery.data?.results.map((lead) => {
      return (
        <TableRow key={lead.id}>
          <TableCell>{lead.full_name || "N/A"}</TableCell>
          <TableCell>{lead.is_qualified ? <Done color="success" /> : <Clear color="action" />}</TableCell>
          <TableCell>
            <QualificationChip qualification={lead.qualification as Qualification} />
          </TableCell>
          <TableCell>{lead.email || "N/A"}</TableCell>
          <TableCell>{lead.phone_number || "N/A"}</TableCell>
          <TableCell>{standardDateFormat(lead.created_time)} | {standardTimeFormat(lead.created_time)}</TableCell>
          <TableCell>
            <Stack spacing={2} direction="row" alignItems="center">
              <Button color="info" startIcon={<Visibility />} onClick={() => navigate(`${URL_LEADS}/${lead.id}`)}>Détails</Button>
            </Stack>
          </TableCell>
        </TableRow>
      )
    })
  }

  function HistoryRows() {
    if (historyQuery.isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Loader />
          </TableCell>
        </TableRow>
      )
    }

    if (historyQuery.isError) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Typography textAlign="center">Une erreur est survenue</Typography>
          </TableCell>
        </TableRow>
      )
    }

    return historyQuery.data?.results.map((lead) => {
      return (
        <TableRow key={lead.id}>
          <TableCell>{lead.full_name || "N/A"}</TableCell>
          <TableCell>{lead.is_qualified ? <Done color="success" /> : <Clear color="action" />}</TableCell>
          <TableCell>
            <QualificationChip qualification={lead.qualification as Qualification} />
          </TableCell>
          <TableCell>{lead.email || "N/A"}</TableCell>
          <TableCell>{lead.phone_number || "N/A"}</TableCell>
          <TableCell>{standardDateFormat(lead.created_time)} | {standardTimeFormat(lead.created_time)}</TableCell>
          <TableCell>
            <Stack spacing={2} direction="row" alignItems="center">
              <Button color="info" startIcon={<Visibility />} onClick={() => navigate(`${URL_LEADS}/${lead.id}`)}>Détails</Button>
            </Stack>
          </TableCell>
        </TableRow>
      )
    })
  }

  function Pagination() {
    if (leadsQuery.isSuccess) {
      return (
        <MainPagination
          page={Number(page)}
          numberOfPages={pagesCount(leadsQuery.data.count, perPage)}
          changePage={setPage}
        />
      )
    }

    return null
  }

  return (
    <div className="dashboard">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
          <Tab label="Liste de leads" icon={<BarChart />} iconPosition="start" sx={{ minHeight: 20 }} />
          <Tab label="Historique" icon={<History />} iconPosition="start" sx={{ minHeight: 20 }} />
        </Tabs>
      </Box>
      <div role="tabpanel" hidden={tabValue !== 0}>
        {tabValue === 0 && (
          <>
            {Filters()}

            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    {Columns()}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Rows()}
                </TableBody>
              </Table>
            </TableContainer>

            {leadsQuery.isSuccess && leadsQuery.data?.results.length === 0 && (
              <Alert variant="standard" color="info">Aucun résultat</Alert>
            )}

            {Pagination()}
          </>
        )}
      </div>
      <div role="tabpanel" hidden={tabValue !== 1}>
        {tabValue === 1 && (
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  {Columns()}
                </TableRow>
              </TableHead>
              <TableBody>
                {HistoryRows()}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  )
}
