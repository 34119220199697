import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_LEADS } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type LeadRequest = {
  id: number
}

export type LeadResponse = {
  id: number
  full_name: string
  email: string
  phone_number: string
  is_qualified: boolean
  qualification: string
  message: string
  choice: {
    id: number
    question: string
    answer: string
  }
  from_fb_api: boolean
  ville: {
    id: number
    name: string
  }
  mode_financement: string
  montat_avance: string
  heur_debut_appel: string
  heur_fin_appel: string
  entreprise: string
  fonction: string
  superficie_souhaite: string
  nombre_chambre: number
  age: number
  sexe: string
  commentaire: string
  produit: string
  campagne: {
    id: string
    created_at: string
    updated_at: string
    name: string
    ticker: number
  },
  created_time: string
  updated_at: string
}

export type LeadError = {
  detail: "400_wrong_login_or_password" | ""
}

export default async function getLead(params: LeadRequest) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: `${API_LEADS}${params.id}/`,
  })

  return response.data as LeadResponse
}
